import React from 'react'
import { Container, Row } from 'reactstrap'

import PageTemplate from '../components/pageTemplate'

let Submitted = () => (
  <PageTemplate title="お問い合わせありがとうございます">
    <Container className="py-5">
      <Row>
        フォームが送信されました。<br />
        担当の者から追ってご連絡させていただきます。<br />
        お問い合わせありがとうございました。
      </Row>
    </Container>
  </PageTemplate>
)

export default Submitted
